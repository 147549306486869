@import 'src/App.scss';

.banner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 1rem;
  background-color: white;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 90px;
}
.mobileBanner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  justify-content: center;
}

.grayBar {
  border-top: solid;
  border-top-color: rgb(226, 225, 225);
  height: 19px;
  background-color: rgb(244, 243, 242);
  width: 100%;
}

.errorBar {
  border-top: solid 0.5px;
  border-top-color: rgb(226, 225, 225);
  background-color: rgb(244, 243, 242);
  width: 100%;
}

.logo {
  height: 22px;
  width: 176px;
  margin-right: 2rem;
  margin-top: 2px;
  margin-left: 20px
}

.mobileLogo {
  height: 22px;
  width: 167px;
  display: flex;
  position: absolute;
  top: 50%;
  left: 47%;
  transform: translate(-50%, -50%);
}

.navigationLinks {
  display: flex;
  align-items: center;
  margin-left: 0;
  margin-top: 0.5rem;
}

.link, .activeLink {
  position: relative;
  text-decoration: none;
  color: black;
  margin: 0 1rem;
  font-family: 'JohnsonTextMedium', Arial;
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 0.2rem;
  line-height: 24px;
  outline: none;

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -1px;
    height: 3px;
    width: 115%;
    background-color: red;
    opacity: 0;
    transition: opacity 0.3s;
    transform: translateX(-50%);
  }

  &:focus {
    outline: 2px solid #0F68B2;
    outline-offset: 0px;
  }

  &:hover::after,
  &:focus::after {
    opacity: 1;
  }
}

.activeLink::after {
  opacity: 1;
}

.profileSection {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 2rem;
}

.mobileProfileSection {
  display: flex;
  position: absolute;
  align-items: center;
  right: 0;
  padding-right: 20px;
}

.userName {
  margin-left: 0.5rem;
  color: black;
  font-weight: 400;
  font-size: 16px;
  line-height: 23.2px;
  font-family: "JohnsonText";
  cursor: pointer;
  background: transparent;
  border: none;
}

.dropdown {
  align-items: center;
  display: flex;
  margin-top: 6px;
  position: absolute;
  right: 0;
  margin-right: 15px;
}

.dropdownContent {
  margin-top: 20px;
  display: block;
  position: absolute;
  right: 0;
  background-color: white;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.25);
  z-index: 1;
  text-align: left;
  width: 100%;
  min-width: 11rem;
  border-radius: 4px;
}

.dropdownDivider {
  color: #CFCAC8;
}

.dropdownContent a, .linkStyle {
  text-decoration: none;
  font-size: 16px;
  color: black;
  display: flex;
  align-items: center;
  margin-left: 20px;

  &[href="/logout"] {
    color: red;
    font-weight: 500;
    font-family: 'JohnsonTextMedium', Arial;
  }
}

.exitIcon {
  margin-right: 5px;
}

.customIconDiv {
  display: flex;
}

.customIcon {
  font-size: 24px;
  margin-right: 5px;
  color: black;
}

.customSvgIcon {
  margin-right: 5px;
  filter: brightness(0%);
  width: 24px;
  height: 24px;
}

.lastLogInInfo {
  font-size: 12px;
  color: #5F5F5F;
  line-height: 18.85px;
  padding: 1px 16px;
  border-bottom: 1.5px solid #CFCAC8;
}

.smallButton {
  width: 80px;
}
@import '../../../variables';

.edit-account-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 400px;
  margin-top: 20px;

  .fields-header {
    font-size: 14px;
    color: black;
    min-width: 600px;
  }

  .form-field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 20px;
  }

  .form-label {
    font-weight: bold;
    font-size: 16px;
    min-width: 600px;
  }

  .form-secondary-text {
    font-size: 14px;
    color: $dark-grey;
    margin-bottom: 10px;
    margin-top: -5px;
    line-height: 1.4;
    white-space: pre-line;
    min-width: 600px;
    font-family: 'JohnsonText', Arial;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    width: 75%;
    margin-top: 20px;
    margin-bottom: 30px;
  }
}

.manage-button button {
  max-width: 100%;
  max-height: 100%;
  width: 200px;
  font-size: 14px;
  padding: 0.2rem;
  margin-top: 0.75rem;
  margin-bottom: 1.5rem;
}

.edit-account-form-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 350px;
  margin-top: 20px;

  .fields-header-mobile {
    font-size: 14px;
    color: black;
    margin-bottom: 10px;
  }

  .form-field-mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 80%;
    margin-bottom: 20px;
  }

  .form-label-mobile {
    font-weight: bold;
    font-size: 16px;
    min-width: 500px;
    font-family: 'JohnsonText', Arial, sans-serif;
    margin-bottom: 5px;
    line-height: 1.4;
    max-width: 300px;
    min-width: 300px;
  }

  .form-secondary-text-mobile {
    font-size: 14px;
    color: $dark-grey;
    margin-bottom: 10px;
    margin-top: -5px;
    line-height: 1.4;
    white-space: pre-line;
    max-width: 350px;
    min-width: 300px;
    font-family: 'JohnsonText', Arial;
  }

  .buttons-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;

    > div {
      margin-bottom: 24px;
      width: 100%;
    }
  }
}
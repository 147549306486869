@import 'src/variables.scss';

.jnj-footer {
    float: center;
}

.middle-section {
    background-color: #F4F3F1;
    min-width: 100vw;
    position: relative;
    left: 0;
    padding-bottom: 100px;
}

.home-section {
    background-color: #F4F3F1;
    width: 100vw;
    height: 38px;
    position: relative;
    left: 0;
}

.home-text {
    font-family: JohnsonText, Arial;
    font-size: 16px;
    font-weight: 550;
    padding: 5px 0 0 0;
    color: black;
}

.home-icon {
    margin-left: 220px;
    margin-right: 2px;
    padding: 5px 0 0 0;
    color: black;
}

.field {
    margin-left: 220px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    font-family: 'JohnsonText', Arial;
}

.field-error {
    margin-left: 220px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
    font-family: 'JohnsonText', Arial;
}

.header {
    margin-left: 220px;
    text-align: left;
    font-family: 'JohnsonDisplay', Arial;
}

.help-message {
    margin-left: 220px;
    margin-bottom: 30px;
    text-align: left;
    display: flex;
    font-family: 'JohnsonText', Arial;
    font-weight: 550;
}

.contact-message {
    margin-left: 220px;
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: left;
    font-family: 'JohnsonDisplay', Arial;
    padding: 16px 0 0 0;
}

.form-label {
    text-align: left;
    font-weight: 550;
    margin-bottom: 10px;
}

.input {
    background-color: white;
    border-radius: 6px;
    width: 400px;
    height: 58.88px;
    padding: 0 0 20px 0;
}

.message {
    background-color: white;
    border-radius: 6px;
    width: 600px;
    height: 110.99px;
    margin-bottom: 20px;
}

.send-message {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    outline: none;
    margin-left: 220px;
}

.send-message button {
    display: flex;
    width: 180px;
    height: 45px;
    font-size: 16px;
    font-weight: 550;
}

.button-content {
    display: flex;
    align-items: center;
    gap: 8px;
}

.textfield {
    align-items: left;
    width: 600px;
    font-family: 'JohnsonText', Arial;
    font-size: 14px;
    font-weight: light;
}

.form-control {
    width: 600px;
    height: 10px;
    border-radius: 6px;
}

.field-mobile {
    margin-left: 3.5%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    font-family: 'JohnsonText', Arial;
}

.field-error-mobile {
    margin-left: 3.5%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
    font-family: 'JohnsonText', Arial;
}

.header-mobile {
    margin-left: 3.5%;
    text-align: left;
    font-family: 'JohnsonDisplay', Arial;
    display: flex;
}

.help-message-mobile {
    margin-left: 3.5%;
    margin-bottom: 30px;
    text-align: left;
    display: flex;
    font-family: 'JohnsonText', Arial;
    font-weight: 550;
}

.contact-message-mobile {
    margin-left: 3.5%;
    margin-bottom: 10px;
    text-align: left;
    display: flex;
    font-family: 'JohnsonDisplay', Arial;
}

.phone-label-mobile {
    text-align: left;
    font-weight: 550;
    margin-bottom: 10px;
    max-width: 350px;
}

.input-mobile {
    background-color: white;
    border-radius: 10px;
    width: 350px;
    height: 58.88px;
    padding: 0 0 20px 0;
}

.message-mobile {
    background-color: white;
    border-radius: 10px;
    width: 350px;
    height: 111px;
    margin-bottom: 20px;
}

.send-message-mobile {
    display: flex;
    margin-left: 3.5%;
}

.send-message-mobile button {
    display: flex;
    width: 180px;
    height: 45px;
    font-size: 16px;
    font-weight: 550;
}

.textfield-mobile {
    align-items: left;
    width: 350px;
    font-family: 'JohnsonText', Arial;
    font-size: 14px;
    font-weight: light;
}

.form-control-mobile {
    width: 350px;
    height: 10px;
    border-radius: 6px;
}

.success-view-mobile {
  padding-top: 40px;
  padding: 15px;
}

.success-view-mobile-button {
  padding-top: 30px;
  padding-right: 65px
}

.success-view-desktop {
  padding-left: 220px;
  padding-right: 600px;
}

.success-view-desktop-button {
  padding-top: 40px;
  padding-right: 1050px;
}
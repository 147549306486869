@import '../../variables';

.home-section {
    margin-top: 20px;
    height: 38px;
    position: relative;
    left: 0;
}

.home-text {
    font-family: JohnsonText, Arial;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.2px;
    padding: 5px 0 0 0;
    color: black;
}

.home-icon {
    margin-right: 2px;
    padding: 5px 0 0 0;
    color: black;
}

.form-secondary-text {
    font-size: 16px;
    font-weight: 400;
    color: black;
    margin-bottom: 10px;
    margin-top: -5px;
    line-height: 1.4;
    white-space: pre-line;
    max-width: 700px;
    text-align: left;
    font-family: 'JohnsonText', Arial;
}

.edit-phone-post-header {
    width: 100%;
    height: 19px;
    background-color: #F4F3F1;
}

.edit-phone-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 32px;
}

.edit-phone-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 227px;
    gap: 15px;
}

.JNJ-footer {
    padding-top: 350px;
}

// Mobile

.edit-phone-mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 350px;
    margin-top: 20px;

    .edit-phone-container-mobile {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
    }

    .form-secondary-text-mobile {
        font-size: 14px;
        color: $dark-grey;
        margin-bottom: 10px;
        margin-top: -5px;
        line-height: 1.4;
        white-space: pre-line;
        max-width: 350px;
        min-width: 300px;
        font-family: 'JohnsonText', Arial;
      }
}